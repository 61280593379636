import { Vue } from 'vue-property-decorator'
import { FactDescription, ValidationError } from '@/interfaces/engine-exports'
import {
  FnOverlayByGroupName,
  FnValidationErrors
} from '@/store/modules/product/getters'
import { Getter } from 'vuex-class'
import { Overlay } from '@uncharted/coverhub-framework'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import store from '@/store'
import {
  ACTION_FACT_VALUE_SET,
  ACTION_VALIDATE_PRODUCT
} from '@/store/modules/product/actionTypes'
import { GroupName } from '@/services/Sales'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'

export class OverlayView extends Vue {
  /**
   * Gets coverage overlay
   */
  @Getter('product/getOverlayByGroupName')
  protected getOverlayByGroupName!: FnOverlayByGroupName

  @Getter('product/getValidationErrors')
  getValidationErrors!: FnValidationErrors

  get validationErrors(): ValidationError[] {
    return this.getValidationErrors()
  }

  /**
   * Coverage overlay
   */
  private overlay: Overlay | null = null

  /**
   * Product engine facts
   */
  protected facts: FactDescription[] = []

  /**
   * Maps component to a specific overlay.
   */
  private groupName: GroupName

  /**
   * Holds a list of facts on page that are invalid
   */
  private invalidFacts: string[] = []

  private pristine = true

  /**
   * Sub-types should provide correct groupName.
   * @param groupName Maps to correct overlay
   */
  constructor(groupName: GroupName) {
    super()
    this.groupName = groupName
    this.onFactValidation = this.onFactValidation.bind(this)
  }

  get valid(): boolean {
    if (this.pristine) {
      return false
    }
    return this.validationErrors.length === 0 && this.invalidFacts.length === 0
  }

  /**
   * Get coverage overlay so this component can render properly.
   */
  doCreated() {
    this.overlay = this.getOverlayByGroupName(this.groupName)
    this.$store.dispatch(ACTION_VALIDATE_PRODUCT, this.groupName)
    const validationResponse = this.overlay?.validate()
    if (
      validationResponse &&
      validationResponse.length > 0 &&
      validationResponse[0].valid
    ) {
      this.pristine = false
    }

    this.facts = this.overlay?.describeFacts().allFacts || []
  }

  setFactValue(evt: FactChangeEvent) {
    if (this.overlay) {
      store.dispatch(ACTION_FACT_VALUE_SET, {
        groupName: this.groupName,
        factId: evt.id,
        value: evt.value
      } as FactValueChange)
      this.facts = this.overlay?.describeFacts().allFacts || []
      this.$forceUpdate()
    }
    this.pristine = false
  }

  onFactValidation(evt: FactValidationEvent) {
    if (evt.valid) {
      const ix = this.invalidFacts.indexOf(evt.id)
      if (ix > -1) {
        this.invalidFacts.splice(ix, 1)
      }
    } else if (!this.invalidFacts.includes(evt.id)) {
      this.invalidFacts.push(evt.id)
    }
  }
}
