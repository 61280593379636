





























import { Component } from 'vue-property-decorator'
import Fact from '@/components/facts/Fact.vue'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { OverlayView } from '@/views/mixins/OverlayView'
import constants from '@/constants'
import ImportantInfo from '@/components/ImportantInfo.vue'
import BackButton from '@/components/layout/BackButton.vue'
import NextButton from '@/components/layout/NextButton.vue'
import { ACTION_VALIDATE_PRODUCT } from '@/store/modules/product/actionTypes'
import { saleJourneyActionHandler, saleJourneyRouteGuard } from '@/services/SaleJourneyService'

@Component({
  name: 'ApplicationForm',
  components: {
    ImportantInfo,
    BackButton,
    NextButton,
    Fact
  },
  beforeRouteLeave(to, from, next) {
    // prevent navigate to next page if invalid
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that: any = this
    if (that.facts.length === 0) {
      next()
    } else if (to.name === constants.routeNames.APPLICATION_FORM && !that.valid) {
      return false
    } else {
      next()
    }
  }
})
export default class ProofCert extends OverlayView {
  constructor () {
    super('certificate')
  }

  created () {
    this.doCreated()
    if (this.facts.length === 0) {
      this.$router.replace({
        name: constants.routeNames.APPLICATION_FORM, params: { skipPoC: 'yes' }
      })
    }
  }

  onFactChange (factChange: FactChangeEvent) {
    this.setFactValue(factChange)
    this.doCreated()
  }

  private async next() {
    await this.$store.dispatch(ACTION_VALIDATE_PRODUCT)
    if (!this.valid) {
      return
    }
    await this.$router.push({ name: constants.routeNames.APPLICATION_FORM })
  }

  mounted() {
    // Sale Journey Route Guard - to handle back button state
    saleJourneyRouteGuard(saleJourneyActionHandler(this.$store.state.product.saleJourneyState), this.$router, this.$store.state.product.invoiceId)
  }
}
