import constants from '@/constants'
import { SaleJourneyState } from '@/store/api/ProductState'
import VueRouter from 'vue-router'

export const SaleJourneyActionType = {
  NORMAL: 'NORMAL',
  RESUBMIT_POLICY: 'RESUBMIT_POLICY', // clear submit flag and take back to summary screen where they can resubmit
  CONVERT_QUOTE: 'CONVERT QUOTE', // convert quote to policy
  POLL_BUNDLE: 'POLL_BUNDLE', // no invoice id, take to summary screen and poll bundle
  READY_MAKE_PAYMENT: 'READY_MAKE_PAYMENT', // converted policy & invoice but payment not started -> take to payment details page and get them to update gmo
  TO_CONFIRM_PAYMENT: 'TO_CONFIRM_PAYMENT', // converted policy & invoice & payment started but no confirm payment -> take to payment details and poll invoice
  GMO_OK: 'GMO_OK', // payment started, gmo redirect back with ok status, should in payment details and check settlement status and navigate to complete if settlement completed
  COMPLETED: 'COMPLETED' // journey ccompleted should restart the sale journey
}

export const saleJourneyActionHandler = (journeyState: SaleJourneyState) => {
  const { isProposalSubmited, isQuotedCreated, isPolicyCreated, isInvoiceIdAvailable, isPaymentStarted, isGmoOk, isPaymentCompleted } = journeyState

  if (isProposalSubmited && !isQuotedCreated && !isPolicyCreated && !isInvoiceIdAvailable && !isPaymentStarted && !isGmoOk && !isPaymentCompleted) {
    return SaleJourneyActionType.RESUBMIT_POLICY
  }

  if (isProposalSubmited && isQuotedCreated && !isPolicyCreated && !isInvoiceIdAvailable && !isPaymentStarted && !isGmoOk && !isPaymentCompleted) {
    return SaleJourneyActionType.CONVERT_QUOTE
  }

  if (isProposalSubmited && isQuotedCreated && isPolicyCreated && !isInvoiceIdAvailable && !isPaymentStarted && !isGmoOk && !isPaymentCompleted) {
    return SaleJourneyActionType.POLL_BUNDLE
  }

  if (isProposalSubmited && isQuotedCreated && isPolicyCreated && isInvoiceIdAvailable && !isPaymentStarted && !isGmoOk && !isPaymentCompleted) {
    return SaleJourneyActionType.READY_MAKE_PAYMENT
  }

  if (isProposalSubmited && isQuotedCreated && isPolicyCreated && isInvoiceIdAvailable && isPaymentStarted && !isGmoOk && !isPaymentCompleted) {
    return SaleJourneyActionType.TO_CONFIRM_PAYMENT
  }

  if (isProposalSubmited && isQuotedCreated && isPolicyCreated && isInvoiceIdAvailable && isPaymentStarted && isGmoOk && !isPaymentCompleted) {
    return SaleJourneyActionType.GMO_OK
  }

  if (isProposalSubmited && isQuotedCreated && isPolicyCreated && isInvoiceIdAvailable && isPaymentStarted && isGmoOk && isPaymentCompleted) {
    return SaleJourneyActionType.COMPLETED
  }

  return SaleJourneyActionType.NORMAL
}

export const saleJourneyRouteGuard = (actionType: string, router: VueRouter, invoiceId: string | null = null) => {
  if (actionType === SaleJourneyActionType.NORMAL) {
    return false
  } else if (actionType === SaleJourneyActionType.RESUBMIT_POLICY || actionType === SaleJourneyActionType.CONVERT_QUOTE || actionType === SaleJourneyActionType.POLL_BUNDLE) {
    router.replace({ name: constants.routeNames.APPLICATION_SUMMARY })
  } else if (actionType === SaleJourneyActionType.READY_MAKE_PAYMENT || actionType === SaleJourneyActionType.TO_CONFIRM_PAYMENT || actionType === SaleJourneyActionType.GMO_OK) {
    router.replace({ name: constants.routeNames.APPLICATION_PAYMENT, query: { invoiceId } })
  } else if (actionType === SaleJourneyActionType.COMPLETED) {
    router.replace({ name: constants.routeNames.DASHBOARD })
  }
}
